<template>
  <v-card class="shadow rounded-3">
    <v-img
        :aspect-ratio="3/4"
        :src="academy.image_path ? s3BucketURL + academy.image_path : defaultImages.workshop"
        height="200"
    >
   </v-img>
    <div class="p-4">
      <h3 class="text-xl line-clamp-1">
        {{ academy.name }}
      </h3>
      <p class="text-sm">
        {{ academy.venue_service }} | {{  academy.workshop_type  }}
      </p>
      <!-- <div class="d-flex justify-space-between align-center">
        <p>
          Starting from:
        </p>
        <p class="font-medium">
          {{ academy.starting_from | toCurrency }}
        </p>
      </div> -->
      <v-btn class="white--text teal-color mt-4" height="36" text @click="packagesModal = true">
        Book Academy
      </v-btn>
    </div>
    <ShopAcademyPackages
        v-if="packagesModal"
        :academy="academy"
        :academyPackageModal="packagesModal"
        @close="packagesModal=false"
    />
  </v-card>
</template>

<script>

import ShopAcademyPackages from "@/components/Shop/Academy/ShopAcademyPackages.vue";
export default {
  components: {ShopAcademyPackages},
  props: {
    academy: Object,
    defaultImages: { type: Object, default: ()=>{}},
  },
  data() {
    return {
      packagesModal: false,
    }
  }
}
</script>

<style scoped>

</style>